import React from "react";
import lodash from "lodash";
import { graphql } from "gatsby";
import BlogCategory from "../../../components/BlogCategory";
import Layout from "../../../components/Layout";
import Header from "../../../components/Header";
import { mapBlogs } from "../../../lib/utility";
import { Helmet } from "react-helmet";

const Resources = ({ data }) => {
    const { blogs, featBlogs } = data;
    const { edges: catBlogPosts = [] } = blogs || {};
    let catPosts = catBlogPosts;
    const { edges: featBlobPosts = [] } = featBlogs || {};
    catPosts.push(...featBlobPosts);
    catPosts.sort((a, b) => {
        return new Date(b.node.date) - new Date(a.node.date);
    });
    let catBlogs = mapBlogs(catPosts);
    catBlogs = lodash.uniqBy(catBlogs, "id");
    let props = {
        catBlogs,
    };
    const header = (
        <Header
            active={["tools-and-resources", "bay-equity-news"]}
            theme="blue-ribbon"
        />
    );
    return (
        <Layout hasHero={false} header={header}>
            <Helmet>
                <title>
                    Bay Equity News - Resources | Bay Equity Home Loans
                </title>
                <meta
                    name="description"
                    content="Welcome to the Resources Category. Check out our range of blog posts with home loan resources, and if you still have questions, give us a call."
                />
            </Helmet>
            <BlogCategory {...props} categories={["Resources"]} />
        </Layout>
    );
};
export default Resources;

export const pageQuery = graphql`
    query ResourcesQuery {
        blogs: allBlog(
            limit: 20
            sort: { fields: [date], order: DESC }
            filter: {
                categories: { in: ["Resources"] }
                type: { eq: "corporate" }
            }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                }
            }
        }
        featBlogs: allBlog(
            limit: 20
            sort: { fields: [date], order: DESC }
            filter: { categories: { in: ["Resources"] }, feature: { eq: true } }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                }
            }
        }
    }
`;
